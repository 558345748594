import {
  Box,
  Card,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fphLogo, fphLogo2 } from "../../../assets/icons";
import {
  useGetSupportedCountriesQuery,
  useRegisterUserMutation,
} from "../../../redux/services/authApiSlice";
import Buttons from "../../common/button";
import { Input, PasswordInput } from "../../common/input";
import { CountryCodeSelectInput, SelectInput } from "../../common/selectinput";
import "./style.css";
import { useForm, Controller } from "react-hook-form";

import { RegExpressions } from "../../../constants/regExp";
import { useDispatch, useSelector } from "react-redux";
import {
  setAuthInfo,
  setLogin,
  setUserName,
} from "../../../redux/features/auth/authSlice";
import { Page } from "../../../routes/config";
import { MessageAlert } from "../../common/alert/alert";
import { cCodes } from "../../../constants/constant";
import { clearAllStates, handleErrors, handleInputFocus } from "../../../utils";
import { PrivacyPolicy } from "./userRegistration/privacyPolicy";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../../common/loader/loader";
import { cNames } from "../../../constants/constant";
import {
  setCountry,
  setSuppCountries,
  setSuppCountriesNames,
} from "../../../redux/features/country/countrySlice";
export const CreateAccount = () => {
  const [key, setKey] = useState("");
  const [termOfUse, setTermsofUse] = useState(false);

  const { countriesCodes, supportedCountries,countriesNames } = useSelector(
    (state: any) => state?.countries
  );
  const { data: data1, isSuccess: isSuccess1 } = useGetSupportedCountriesQuery({
    refetchOnMountOrArgChange: true,
  });

  //API Integration
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "onChange" || "onSubmit",
  });
  const [registerUser, registerUserData] = useRegisterUserMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [height, setHeight] = useState(0);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const onSubmit = (data: any) => {
    var userData = {
      country: data?.billingCountry,
      countryCode: data?.countryCode?.split(" ")[0]
        ? data?.countryCode?.split(" ")[0]
        : "",
      mobileNumber: data?.mobileNumber?.replaceAll("-", ""),
      email: data?.email,
      fullName: data?.fullName,
      password: data?.password,
    };
    registerUser(userData);
  };
  // const countryOptions = [
  //   { text: "India", value: "India" },
  //   { text: "US", value: "US" },
  //   { text: "UAE", value: "UAE" },
  //   { text: "UK", value: "UK" },
  //   { text: "Canada", value: "Canada" },
  // ];
  const inputRef = useRef<HTMLInputElement | null>();

  useEffect(() => {
    if (isSuccess1) {
      dispatch(
        setCountry({ supportedCountries: data1?.result[0]?.supportedCountries })
      );
      let codeArray: any = [];
      let codeNameArray:any=[]

      let data = data1?.result[0]?.supportedCountries?.map(
        (countryObj: any, index: any) => {
          cCodes?.reduce((acc: any, codeObj: any) => {
            if (countryObj?.countryCode === codeObj?.countryCode) {
              codeArray?.push(codeObj);
            } else {
              return;
            }
            return codeArray;
          }, []);
        }
      );
      let namesData = data1?.result[0]?.supportedCountries?.map(
        (countryObj: any, index: any) => {
          cNames?.reduce((acc: any, codeObj: any) => {
            if (countryObj?.countryCode === codeObj?.value) {
              codeNameArray?.push(codeObj);
            } else {
              return;
            }
            return codeNameArray;
          }, []);
        }
      );
      dispatch(setSuppCountries({ countriesCodes: codeArray?.sort((a:any, b:any) =>
        a?.text.localeCompare(b?.text)
      )}));
      dispatch(setSuppCountriesNames({ countriesNames: codeNameArray.sort((a:any, b:any) =>
        a?.text.localeCompare(b?.text)
      ) }));
    }
  }, [isSuccess1, cCodes,cNames]);

  useEffect(() => {
    if (registerUserData?.isSuccess) {
      const { accessToken, idToken, refreshToken, userInfo, tokenScope } =
        registerUserData?.data?.result;
      // setting auth state
      clearAllStates(dispatch);
      dispatch(
        setAuthInfo({
          userInfo,
          accessToken,
          idToken,
          refreshToken,
          tokenScope,
          userId: userInfo?.userId,
        })
      );
      dispatch(
        setUserName({
          fullName: userInfo?.fullName,
        })
      );
      dispatch(setLogin(true));
      // dispatch(setAlert({ open: true, alertType: "success", message: "Your account has been created successfully" }));
      navigate(Page.REGISTERATION);
    }
  }, [registerUserData?.isSuccess]);

  useEffect(() => {
    handleErrors(registerUserData, dispatch);
  }, [registerUserData?.isError]);

  const onSignInClick = () => {
    navigate("/");
  };

  const handleMobileNumber = (e: any) => {
    var value = handleInputFocus(e, key);
    setValue("mobileNumber", value);
    trigger("mobileNumber");
  };

  // const [countries, setCountries] = useState<any>();

  // useEffect(() => {
  //   let data = supportedCountries?.map((item: any, index: any) => {
  //     cCodes?.reduce((acc:any,codeItem:any)=>{
  //       if(item?.countryCode)
  //     },{})
  //   });
  // }, [cCodes, supportedCountries]);

  const handleCountryChange = (e: any) => {
    const selectedCountryCode = countriesCodes.filter((item: any, index: any) => {
      return item?.countryCode === e?.target?.value;
    });
    if (selectedCountryCode) {
      setValue("countryCode", selectedCountryCode[0]?.value);
    }
    console.log(selectedCountryCode, "selectedCountryCode");
    // if (e?.target?.value === "US") {
    //   setValue("countryCode", "+1 US");
    //   trigger("countryCode");
    // } else if (e?.target?.value === "India") {
    //   setValue("countryCode", "+91 IN");
    //   trigger("countryCode");
    // } else if (e?.target?.value === "UAE") {
    //   setValue("countryCode", "+971 AE");
    //   trigger("countryCode");
    // } else if (e?.target?.value === "UK") {
    //   setValue("countryCode", "+44 GB");
    //   trigger("countryCode");
    // } else if (e?.target?.value === "Canada") {
    //   setValue("countryCode", "+1 CA");
    //   trigger("countryCode");
    // }
  };
  const onPrivacyPolicyClick = () => {
    setShowPrivacyModal(true);
  };
  const onModalClose = () => {
    setShowPrivacyModal(false);
    setTermsofUse(false);
  };
  const onTermsofuseClick = () => {
    setTermsofUse(true);
    setShowPrivacyModal(true);
  };

  useEffect(() => {
    function updateSize() {
      setHeight(window.innerHeight);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  // useEffect(() => {
  //   setValue("billingCountry", "US");
  //   setValue("countryCode", "+1 US");
  //   trigger("countryCode");
  // }, []);

  const match = useMediaQuery("(max-width:1535px)");

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      var x: any = document.getElementById("box-login");
      setTimeout(() => {
        x.scrollTo(0, 0);
      }, 50);
    }
  }, []);

  return (
    <>
      <Loader show={registerUserData?.isLoading} />
      <Box
        height={`${height}px`}
        sx={{ width: "100%", backgroundColor: "#F0F1F4", overflow: "auto" }}
        id="box-login"
      >
        <MessageAlert></MessageAlert>
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            lg={1}
            xl={4}
            sx={{
              paddingTop: !match ? "41px" : "20px",
              paddingLeft: !match ? "43px" : "20px",
            }}
          >
            <img
              src={!match ? fphLogo : fphLogo2}
              className="fphLogocrete"
            ></img>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={11}
            xl={8}
            paddingTop={!match ? "71px" : "40px"}
          >
            <Grid container className="creteCard">
              <Grid item xs={10} md={8} lg={7} xl={6.6}>
                <Card className="Card" data-testid="card-Account">
                  <Grid container justifyContent="center">
                    <Grid item xs={8.6}>
                      <Grid item xs={12}>
                        <Typography className="crteText">
                          Create your account
                        </Typography>
                      </Grid>
                      <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="formCard"
                      >
                        <Grid container>
                          <Grid item xs={12} sx={{ marginTop: "40px" }}>
                            <Controller
                              render={({
                                field: { ref, ...field },
                                fieldState: { invalid, error },
                              }) => (
                                <SelectInput
                                  // disabled
                                  {...field}
                                  value={field.value ? `${field.value}` : ""}
                                  error={Boolean(error)}
                                  helperText={
                                    error?.message ? error?.message : " "
                                  }
                                  placeholder="Select subscriber country"
                                  required={true}
                                  onChange={(e: any) => {
                                    field.onChange(e);
                                    handleCountryChange(e);
                                  }}
                                  inputRef={(e: any) => {
                                    ref(e);
                                    inputRef.current = e;
                                  }}
                                  label="Subscriber's Country"
                                  optionValue={countriesNames}
                                />
                              )}
                              name="billingCountry"
                              control={control}
                              rules={{
                                required: "Please select the country",
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sx={{ paddingTop: "10px" }}>
                            <Controller
                              render={({
                                field: { ref, ...field },
                                fieldState: { invalid, error },
                              }) => (
                                <Input
                                  {...field}
                                  label="Full Name"
                                  value={field.value}
                                  required={true}
                                  error={Boolean(error)}
                                  helperText={
                                    error?.message ? error?.message : " "
                                  }
                                  onChange={field.onChange}
                                  // autoFocus={true}
                                  placeholder="First and last name"
                                />
                              )}
                              name="fullName"
                              control={control}
                              rules={{
                                required: "Please enter full name",
                                pattern: {
                                  value: RegExpressions?.FullName,
                                  message:
                                    "Enter a valid name with alphanumeric characters only.",
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sx={{ paddingTop: "10px" }}>
                            <Grid container spacing={1}>
                              <Grid item xs={3} sx={{ alignSelf: "self-end" }}>
                                <Controller
                                  render={({
                                    field: { ref, ...field },
                                    fieldState: { invalid, error },
                                  }) => (
                                    <CountryCodeSelectInput
                                      {...field}
                                      value={
                                        field.value ? `${field.value}` : ""
                                      }
                                      error={Boolean(error)}
                                      helperText={
                                        error?.message ? error?.message : " "
                                      }
                                      required={true}
                                      onChange={field.onChange}
                                      inputRef={ref}
                                      label="Country Code"
                                      optionValue={countriesCodes}
                                      placeholder="Select"
                                      isCountryCode={true}
                                      disabled
                                    />
                                  )}
                                  name="countryCode"
                                  control={control}
                                  rules={{
                                    required: {
                                      value: true,
                                      message: "",
                                    },
                                  }}
                                />
                              </Grid>
                              <Grid item xs={9}>
                                <Controller
                                  render={({
                                    field: { ref, ...field },
                                    fieldState: { invalid, error },
                                  }) => (
                                    <Input
                                      {...field}
                                      label="Mobile Number"
                                      value={field.value}
                                      error={Boolean(error)}
                                      required={true}
                                      onKeyDown={(e: any) => setKey(e.key)}
                                      helperText={
                                        error?.message ? error?.message : " "
                                      }
                                      onChange={field.onChange}
                                      placeholder="Enter mobile number"
                                      inputRef={ref}
                                    />
                                  )}
                                  name="mobileNumber"
                                  control={control}
                                  rules={{
                                    required: {
                                      value: true,
                                      message:
                                        "Please enter a valid mobile number",
                                    },
                                    pattern: {
                                      value:
                                        RegExpressions?.InternationalMobileNumber,
                                      message:
                                        "Please enter a valid mobile number. ",
                                    },
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sx={{ paddingTop: "10px" }}>
                            <Controller
                              render={({
                                field: { ref, ...field },
                                fieldState: { invalid, error },
                              }) => (
                                <Input
                                  {...field}
                                  label="Email"
                                  value={field.value}
                                  required={true}
                                  error={Boolean(error)}
                                  helperText={
                                    error?.message ? error?.message : " "
                                  }
                                  onChange={field.onChange}
                                  placeholder="Enter email address"
                                  inputRef={ref}
                                />
                              )}
                              name="email"
                              control={control}
                              rules={{
                                required:
                                  "Please enter a valid email address. (Ex - abcd@xyz.com)",
                                pattern: {
                                  value: RegExpressions?.Email,
                                  message:
                                    "Please enter a valid email address. (Ex - abcd@xyz.com)",
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sx={{ paddingTop: "10px" }}>
                            <Controller
                              render={({
                                field: { ref, ...field },
                                fieldState: { invalid, error },
                              }) => (
                                <PasswordInput
                                  {...field}
                                  label="Password"
                                  value={field.value}
                                  required={true}
                                  error={Boolean(error)}
                                  helperText={
                                    error?.message ? error?.message : " "
                                  }
                                  onChange={field.onChange}
                                  inputRef={ref}
                                  placeholder="Enter password"
                                  info={true}
                                />
                              )}
                              name="password"
                              control={control}
                              rules={{
                                required: "Please enter the password.",
                                pattern: {
                                  value: RegExpressions?.Password,
                                  message: "Please enter the valid password.",
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sx={{ paddingTop: "10px" }}>
                            <Controller
                              render={({
                                field: { ref, ...field },
                                fieldState: { invalid, error },
                              }) => (
                                <PasswordInput
                                  {...field}
                                  label="Retype Password"
                                  value={field.value}
                                  required={true}
                                  error={Boolean(error)}
                                  helperText={
                                    error?.message ? error?.message : " "
                                  }
                                  onChange={field.onChange}
                                  placeholder="Re-enter password"
                                  inputRef={ref}
                                />
                              )}
                              name="confirmPassword"
                              control={control}
                              rules={{
                                required: "Please enter the retype password",
                                validate: () => {
                                  if (
                                    getValues("password") !=
                                    getValues("confirmPassword")
                                  ) {
                                    return "Password and retype password does not match.";
                                  }
                                },
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sx={{ marginTop: "20px" }}
                            className="createAccountBtn"
                          >
                            <Buttons
                              buttonType="submit"
                              variant="contained"
                              text="CREATE ACCOUNT"
                              type="primary"
                            />
                          </Grid>
                          <Grid item xs={12} sx={{ marginTop: "20" }}>
                            <p className="privacyText">
                              Click to view{" "}
                              <a
                                href="#"
                                className="addunderline"
                                onClick={onPrivacyPolicyClick}
                              >
                                Privacy Policy
                              </a>{" "}
                              and{" "}
                              <a
                                href="#"
                                className="addunderline"
                                onClick={onTermsofuseClick}
                              >
                                Terms of Use
                              </a>
                            </p>
                          </Grid>
                        </Grid>
                      </form>
                      <Divider sx={{ mb: 1.6, mt: 2 }} />
                      <Grid container>
                        <Divider
                          variant="middle"
                          className="createDivider alreadyAccount"
                        />
                        <Grid item xs={12} className="already-block">
                          <span className="alreadyText">
                            Already have an account?{" "}
                            <a
                              href="/"
                              className="signInText addunderline"
                              onClick={onSignInClick}
                            >
                              Sign In
                            </a>
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" className="createHelpContact">
          <Grid item xs={12} md={12} lg={12} xl={8}>
            <p className="copyrightDiv">
              <span className="helpText">
                <a
                  href="https://familyprohealth.com/faq"
                  className="addunderline"
                >
                  Help
                </a>{" "}
                &nbsp;|&nbsp;{" "}
                <a
                  href="https://familyprohealth.com/contact-us"
                  className="addunderline"
                >
                  Contact us
                </a>
              </span>
              <span className="copyrightText">
                {" "}
                ©{new Date().getFullYear()} familyprohealth Inc. All rights
                reserved.
              </span>
            </p>
          </Grid>
        </Grid>
        <Dialog open={showPrivacyModal} className="PrivacyPolicyModal">
          <DialogTitle className="privacyCardText">
            {termOfUse ? "Terms Of Use" : "Privacy Policy"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={onModalClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <PrivacyPolicy
            modal={true}
            termofUseModal={termOfUse}
            setTermofUseModal={setTermsofUse}
          />
        </Dialog>
      </Box>
    </>
  );
};
