import {
  Card,
  Checkbox,
  Divider,
  Grid,
  fabClasses,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Input } from "../../../common/input";
import { Box } from "@mui/system";
import Buttons from "../../../common/button";
import {
  CountryCodeSelectInput,
  SelectInput,
} from "../../../common/selectinput";
import { State } from "country-state-city";
import {
  useAddOrderAddressMutation,
  useGetOrderAddressMutation,
  useUpdateOrderAddressMutation,
} from "../../../../redux/services/orderApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../../redux/features/alert/alertSlice";
import { setUserSteps } from "../../../../redux/features/userStep/userStepSlice";
import { cCodes } from "../../../../constants/constant";
import { RegExpressions } from "../../../../constants/regExp";
import "./billingShippingAddress.css";

import {
  getCountryNameByCountryCode,
  handleErrors,
  handleInputFocus,
  phoneNumberAutoFormat,
} from "../../../../utils";
import Loader from "../../../common/loader/loader";

export const BillingShippingAddress = (props: any) => {
  const { userInfo } = useSelector((state: any) => state?.auth);
  console.log(userInfo, "userInfo");

  const [key, setKey] = useState("");
  const dispatch = useDispatch();
  const { activeStep, setActiveStep, setCompleted, completed } = props;
  const [addOrderAddress, addOrderAddressData] = useAddOrderAddressMutation();
  const [billingCountry, setBillingCountry] = useState("");
  const [showShippingAddress, setShowShippingAddress] = useState<any>(true);
  const [check, setCheck] = useState<any>(true);
  console.log(!check, "checkcheck");
  const [updateOrderAddress, updateOrderAddressData] =
    useUpdateOrderAddressMutation();
  const [isUpdate, setIsUpdate] = useState<Boolean>(false);
  const [getOrderAddressById, getOrderAddressByIdData] =
    useGetOrderAddressMutation();
  const { beneficiaryData } = useSelector((state: any) => state?.beneficiary);

  const { id } = useSelector((state: any) => state?.order);

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "onChange" || "onSubmit",
  });
  const indiaStateNames = State.getStatesOfCountry("IN").map((states: any) => {
    return { text: states?.name, value: `${states?.isoCode}` };
  });
  const { countriesCodes, supportedCountries, countriesNames } = useSelector(
    (state: any) => state?.countries
  );
  const usStateNames = State.getStatesOfCountry(userInfo?.country).map(
    (states: any) => {
      return { text: states?.name, value: `${states?.isoCode}` };
    }
  );

  const onSubmit = (data: any) => {
    var finalData = {
      orderId: id,
      billing: {
        houseNo: data?.billingHouseNo,
        locality: data?.billingArea,
        city: data?.billingTown,
        state: data?.billingState,
        zipCode: data?.billingZip,
        country: data?.billingCountry,
      },
      shipping: {
        fullName: data?.fullName,
        mobileNumber: data?.mobileNumber?.replaceAll("-", ""),
        houseNo: data?.shippingHouseNo,
        locality: data?.shippingArea,
        city: data?.shippingTown,
        state: data?.shippingState,
        countryCode: data?.countryCode?.split(" ")[0]
          ? data?.countryCode?.split(" ")[0]
          : "",
        zipCode: data?.shippingPincode,
        country: data?.shippingCountry,
      },
    };
    var payload = {
      orderId: id,
      billing: {
        houseNo: data?.billingHouseNo,
        locality: data?.billingArea,
        city: data?.billingTown,
        state: data?.billingState,
        zipCode: data?.billingZip,
        country: data?.billingCountry,
      },
      shipping: {
        fullName: userInfo?.fullName,
        mobileNumber: userInfo?.mobileNumber?.replaceAll("-", ""),
        houseNo: data?.billingHouseNo,
        locality: data?.billingArea,
        city: data?.billingTown,
        state: data?.billingState,
        countryCode: data?.countryCode?.split(" ")[0]
          ? data?.countryCode?.split(" ")[0]
          : "",
        zipCode: data?.billingZip,
        country: data?.billingCountry,
      },
    };

    if (!isUpdate) {
      if (check) {
        addOrderAddress(payload);
        console.log(payload, "paylooo");
      } else {
        addOrderAddress(finalData);
        console.log(finalData, "finallll");
      }
    } else {
      if(check){
        updateOrderAddress(payload);
      } else{
        updateOrderAddress(finalData);
        console.log(finalData, "finalData");
      }
    }
  };

  const getOrderAddress = () => {
    getOrderAddressById(id);
  };

  useEffect(() => {
    if (addOrderAddressData?.isSuccess) {
      var newCompleted = {};
      Object.assign(newCompleted, completed);
      Object.assign(newCompleted, { [activeStep]: true });
      dispatch(
        setUserSteps({
          activeSteps: Object.keys(newCompleted)?.length,
          completedSteps: newCompleted,
        })
      );
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "Address Saved Successfully",
        })
      );
    }
  }, [addOrderAddressData?.isSuccess]);

  // useEffect(() => {
  //     if (addOrderAddressData?.error?.status == 500 || addOrderAddressData?.error?.status == 400) {
  //         dispatch(setAlert({ open: true, alertType: "error", message: "Something Went Wrong" }))
  //     }
  //     else if (addOrderAddressData?.isError) {
  //         dispatch(setAlert({ open: true, alertType: "error", message: addOrderAddressData?.error?.data?.error ? addOrderAddressData?.error?.data?.error?.message : addOrderAddressData?.error?.data?.length? addOrderAddressData?.error?.data[0]?.message:"Something Went Wrong" }));
  //     }
  // }, [addOrderAddressData?.isError])

  useEffect(() => {
    handleErrors(addOrderAddressData, dispatch);
  }, [addOrderAddressData?.isError]);

  useEffect(() => {
    if (updateOrderAddressData?.isSuccess) {
      var newCompleted = {};
      Object.assign(newCompleted, completed);
      Object.assign(newCompleted, { [activeStep]: true });
      dispatch(
        setUserSteps({
          activeSteps: Object.keys(newCompleted)?.length,
          completedSteps: newCompleted,
        })
      );
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "Address Updated Successfully",
        })
      );
    }
  }, [updateOrderAddressData?.isSuccess]);

  // useEffect(() => {
  //     if (updateOrderAddressData?.error?.status == 500 || updateOrderAddressData?.error?.status == 400) {
  //         dispatch(setAlert({ open: true, alertType: "error", message: "Something Went Wrong" }))
  //     }
  //     else if (updateOrderAddressData?.isError) {
  //         dispatch(setAlert({ open: true, alertType: "error", message: updateOrderAddressData?.error?.data?.error ? updateOrderAddressData?.error?.data?.error?.message : updateOrderAddressData?.error?.data?.length? updateOrderAddressData?.error?.data[0]?.message:"Something Went Wrong" }));
  //     }
  // }, [updateOrderAddressData?.isError])

  useEffect(() => {
    handleErrors(updateOrderAddressData, dispatch);
  }, [updateOrderAddressData?.isError]);

  useEffect(() => {
    if (getOrderAddressByIdData?.isSuccess) {
      if (
        getOrderAddressByIdData?.data &&
        getOrderAddressByIdData?.data?.result &&
        getOrderAddressByIdData?.data?.result?.length > 0
      ) {
        setIsUpdate(true);
        var billingData = getOrderAddressByIdData?.data?.result?.filter(
          (data: any) => data?.addressType == "billing"
        );
        setValue("billingHouseNo", billingData[0]?.address1);
        setValue("billingArea", billingData[0]?.address2);
        setValue("billingTown", billingData[0]?.city);
        setValue("billingState", billingData[0]?.state);
        setValue("billingZip", billingData[0]?.zipCode);
        setValue("billingCountry", billingData[0]?.country);
        var shippingData = getOrderAddressByIdData?.data?.result?.filter(
          (data: any) => data?.addressType == "shipping"
        );
        setValue("fullName", shippingData[0]?.fullName);
        setValue(
          "mobileNumber",
          phoneNumberAutoFormat(shippingData[0]?.mobileNumber)
        );
        setValue("countryCode", "+91 IN");
        setValue("shippingHouseNo", shippingData[0]?.address1);
        setValue("shippingArea", shippingData[0]?.address2);
        setValue("shippingTown", shippingData[0]?.city);
        setValue("shippingState", shippingData[0]?.state);
        setValue("shippingPincode", shippingData[0]?.zipCode);
        setValue("shippingCountry", shippingData[0]?.country);
      } else {
        setIsUpdate(false);
        const name = getCountryNameByCountryCode(userInfo.country);
        setBillingCountry(name);
        setValue("billingCountry", name);
        setValue("shippingCountry", "India");
        setValue("countryCode", "+91 IN");
      }
    }
  }, [getOrderAddressByIdData?.isSuccess]);

  // useEffect(() => {
  //     if (getOrderAddressByIdData?.error?.status == 500 || getOrderAddressByIdData?.error?.status == 400) {
  //         dispatch(setAlert({ open: true, alertType: "error", message: "Something Went Wrong" }))
  //     }
  //     else if (getOrderAddressByIdData?.isError) {
  //         setIsUpdate(false);
  //         dispatch(setAlert({ open: true, alertType: "error", message: getOrderAddressByIdData?.error?.data?.error ? getOrderAddressByIdData?.error?.data?.error?.message : getOrderAddressByIdData?.error?.data?.length? getOrderAddressByIdData?.error?.data[0]?.message:"Something Went Wrong" }))
  //     }
  // }, [getOrderAddressByIdData?.isError])

  useEffect(() => {
    handleErrors(getOrderAddressByIdData, dispatch);
  }, [getOrderAddressByIdData?.isError]);

  useEffect(() => {
    // if (beneficiaryData?.length != 0) {
    getOrderAddress();
    // }
  }, []);
  useEffect(() => {
    // setValue("billingCountry", "United States of America");
    setValue("shippingCountry", "India");
    setValue("countryCode", "+91 IN");
  }, []);

  const handleMobileNumber = (e: any) => {
    var value = handleInputFocus(e, key);
    setValue("mobileNumber", value);
    trigger("mobileNumber");
  };
  const matches = useMediaQuery("(max-width:1300px)");

  useEffect(() => {
    const name = getCountryNameByCountryCode(userInfo.country);
    setBillingCountry(name);
    setValue("billingCountry", name);
  }, [userInfo?.country]);

  const handleCheckboxChange = (event: any) => {
    setShowShippingAddress(event?.target?.checked);
    setCheck(event?.target?.checked);
  };

  useEffect(() => {
    if (userInfo?.country === "IN") {
      setShowShippingAddress(true);
    } else {
      setShowShippingAddress(false);
      setCheck(false);
    }
  }, []);

  return (
    <>
      <Loader
        show={
          addOrderAddressData?.isLoading ||
          getOrderAddressByIdData?.isLoading ||
          updateOrderAddressData?.isLoading
        }
      />
      <Box marginTop={"4vh"} marginBottom={"4vh"} className="mainBoxcContainer">
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} sm={12} md={11} lg={11.2} xl={11.4}>
            <Card data-testid="main-card">
              <Grid container justifyContent={"center"}>
                <Grid
                  item
                  xs={11}
                  sm={11}
                  md={11}
                  lg={10}
                  xl={10}
                  className="p-TopBottom"
                >
                  <span
                    className="billingShippingHeader"
                    data-testid="billing-title"
                  >
                    Enter your addresses
                  </span>
                  <Divider className="billingDivider" />

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="policyConditions mt-40">
                      <Grid container columnSpacing={4} rowSpacing={1}>
                        <Grid
                          item
                          xs={12}
                          className="billingHeading"
                          data-testid="billing-heading"
                        >
                          <span>Enter Subscriber billing address</span>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <Controller
                            render={({
                              field: { ref, ...field },
                              fieldState: { invalid, error },
                            }) => (
                              <Input
                                {...field}
                                label="House No, Street"
                                value={field.value}
                                required={true}
                                error={Boolean(error)}
                                helperText={
                                  error?.message ? error?.message : " "
                                }
                                onChange={field.onChange}
                                inputRef={ref}
                              />
                            )}
                            name="billingHouseNo"
                            control={control}
                            rules={{
                              required: "Please enter the house no, street",
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <Controller
                            render={({
                              field: { ref, ...field },
                              fieldState: { invalid, error },
                            }) => (
                              <Input
                                {...field}
                                label="Area, Locality"
                                value={field.value}
                                error={Boolean(error)}
                                helperText={
                                  error?.message ? error?.message : " "
                                }
                                onChange={field.onChange}
                                inputRef={ref}
                              />
                            )}
                            name="billingArea"
                            control={control}
                          />
                        </Grid>
                        <Grid item xs={12} lg={6} xl={3}>
                          <Controller
                            render={({
                              field: { ref, ...field },
                              fieldState: { invalid, error },
                            }) => (
                              <Input
                                {...field}
                                label="Town/City"
                                value={field.value}
                                required={true}
                                error={Boolean(error)}
                                helperText={
                                  error?.message ? error?.message : " "
                                }
                                onChange={field.onChange}
                                inputRef={ref}
                              />
                            )}
                            name="billingTown"
                            control={control}
                            rules={{
                              required: "Please enter town/city",
                              pattern: {
                                value: RegExpressions?.noSpaceatBeginningAndEnd,
                                message: "Please enter valid town/city",
                              },
                            }}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          lg={6}
                          xl={3}
                          className="selectInputAlign stateMarginTop"
                        >
                          <Controller
                            render={({
                              field: { ref, ...field },
                              fieldState: { invalid, error },
                            }) => (
                              <SelectInput
                                {...field}
                                label="State"
                                value={`${field.value}`}
                                error={Boolean(error)}
                                helperText={
                                  error?.message ? error?.message : " "
                                }
                                required={true}
                                onChange={field.onChange}
                                optionValue={usStateNames}
                                inputRef={ref}
                              />
                            )}
                            name="billingState"
                            control={control}
                            rules={{
                              required: "Please select the state ",
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} lg={6} xl={3}>
                          <Controller
                            render={({
                              field: { ref, ...field },
                              fieldState: { invalid, error },
                            }) => (
                              <Input
                                {...field}
                                label="Postal Code"
                                value={field.value}
                                required={true}
                                error={Boolean(error)}
                                helperText={
                                  error?.message ? error?.message : " "
                                }
                                onChange={field.onChange}
                                inputRef={ref}
                              />
                            )}
                            name="billingZip"
                            control={control}
                            rules={{
                              required: "Please enter zip code",
                              pattern: {
                                value: RegExpressions?.ZipCode,
                                message: "Please enter valid zip code",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} lg={6} xl={3}>
                          <Controller
                            render={({
                              field: { ref, ...field },
                              fieldState: { invalid, error },
                            }) => (
                              <Input
                                {...field}
                                label="Country"
                                disabled
                                value={field?.value}
                                required={true}
                                error={Boolean(error)}
                                helperText={
                                  error?.message ? error?.message : " "
                                }
                                onChange={field.onChange}
                                inputRef={ref}
                              />
                            )}
                            name="billingCountry"
                            control={control}
                            rules={{
                              required: "Please enter Country",
                            }}
                          />
                        </Grid>
                      </Grid>

                      {userInfo?.country === "IN" && (
                        <Grid container>
                          <Grid item xs={12} sx={{mb:"4px"}} >
                            <Checkbox
                            style={{marginLeft:"-10px"}}
                              className="checkboxBox"
                              defaultChecked={check}
                              onChange={handleCheckboxChange}
                            />
                            <span>
                              Beneficiary(Elder) address same as billing address
                            </span>
                          </Grid>
                        </Grid>
                      )}

                      {userInfo?.country !== "IN" && (
                        <Divider
                          sx={{ marginBottom: "36px" }}
                          className="billingDivider"
                        />
                      )}
                      <Grid container columnSpacing={4} rowSpacing={1}>
                        {!showShippingAddress && (
                          <>
                            <Grid item xs={12} className="billingHeading">
                              <span>Enter Beneficiary(Elder) Address</span>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <Controller
                                name="fullName"
                                control={control}
                                rules={{
                                  required: "Please enter full name",
                                  pattern: {
                                    value: RegExpressions?.FullName,
                                    message:
                                      "Enter a valid name with alphanumeric characters only.",
                                  },
                                }}
                                render={({
                                  field: { ref, ...field },
                                  fieldState: { invalid, error },
                                }) => (
                                  <Input
                                    {...field}
                                    label="Full Name"
                                    value={field.value}
                                    placeholder="Name of contact at delivery address"
                                    required={true}
                                    error={Boolean(error)}
                                    helperText={
                                      error?.message ? error?.message : " "
                                    }
                                    onChange={field.onChange}
                                    inputRef={ref}
                                  />
                                )}
                              />
                            </Grid>

                            <Grid
                              item
                              xs={4}
                              lg={2}
                              sx={{ alignSelf: "self-end" }}
                            >
                              <Controller
                                render={({
                                  field: { ref, ...field },
                                  fieldState: { invalid, error },
                                }) => (
                                  <CountryCodeSelectInput
                                    {...field}
                                    value={`${field.value}`}
                                    error={Boolean(error)}
                                    helperText={
                                      error?.message ? error?.message : " "
                                    }
                                    required={true}
                                    disabled
                                    onChange={field.onChange}
                                    labelClassName="countryCodeSelect"
                                    inputRef={ref}
                                    label="Country Code"
                                    optionValue={countriesCodes}
                                    placeholder="Select"
                                    isCountryCode={true}
                                  />
                                )}
                                name="countryCode"
                                control={control}
                                rules={{
                                  required: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={8} lg={4}>
                              <Controller
                                name="mobileNumber"
                                control={control}
                                rules={{
                                  required: {
                                    value: true,
                                    message:
                                      "Please enter a valid 10-digit mobile number",
                                  },
                                  pattern: {
                                    value: RegExpressions?.MobileNumber,
                                    message:
                                      "Please enter a valid 10-digit mobile number",
                                  },
                                }}
                                render={({
                                  field: { ref, ...field },
                                  fieldState: { invalid, error },
                                }) => (
                                  <Input
                                    {...field}
                                    placeholder="XXX-XXX-XXXX"
                                    label="Mobile Number"
                                    value={field.value}
                                    required={true}
                                    error={Boolean(error)}
                                    onKeyDown={(e: any) => setKey(e.key)}
                                    helperText={
                                      error?.message ? error?.message : " "
                                    }
                                    onChange={(e: any) => {
                                      field.onChange(e);
                                      handleMobileNumber(e);
                                    }}
                                    inputRef={ref}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <Controller
                                render={({
                                  field: { ref, ...field },
                                  fieldState: { invalid, error },
                                }) => (
                                  <Input
                                    {...field}
                                    label="House No, Street"
                                    value={field.value}
                                    required={true}
                                    error={Boolean(error)}
                                    helperText={
                                      error?.message ? error?.message : " "
                                    }
                                    onChange={field.onChange}
                                    inputRef={ref}
                                  />
                                )}
                                name="shippingHouseNo"
                                control={control}
                                rules={{
                                  required: "Please enter house No, street",
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <Controller
                                render={({
                                  field: { ref, ...field },
                                  fieldState: { invalid, error },
                                }) => (
                                  <Input
                                    {...field}
                                    label="Area, Locality"
                                    value={field.value}
                                    error={Boolean(error)}
                                    helperText={
                                      error?.message ? error?.message : " "
                                    }
                                    onChange={field.onChange}
                                    inputRef={ref}
                                  />
                                )}
                                name="shippingArea"
                                control={control}
                              />
                            </Grid>
                            <Grid item xs={12} lg={3}>
                              <Controller
                                render={({
                                  field: { ref, ...field },
                                  fieldState: { invalid, error },
                                }) => (
                                  <Input
                                    {...field}
                                    label="Town/City"
                                    value={field.value}
                                    required={true}
                                    error={Boolean(error)}
                                    helperText={
                                      error?.message ? error?.message : " "
                                    }
                                    onChange={field.onChange}
                                    inputRef={ref}
                                  />
                                )}
                                name="shippingTown"
                                control={control}
                                rules={{
                                  required: "Please enter town/city",
                                  pattern: {
                                    value:
                                      RegExpressions?.noSpaceatBeginningAndEnd,
                                    message: "Please enter valid town/city",
                                  },
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              lg={3}
                              className="selectInputAlign stateMarginTop"
                            >
                              <Controller
                                render={({
                                  field: { ref, ...field },
                                  fieldState: { invalid, error },
                                }) => (
                                  <SelectInput
                                    {...field}
                                    value={`${field.value}`}
                                    error={Boolean(error)}
                                    helperText={
                                      error?.message ? error?.message : " "
                                    }
                                    required={true}
                                    onChange={field.onChange}
                                    inputRef={ref}
                                    label="State"
                                    optionValue={indiaStateNames}
                                    placeholder="Select"
                                  />
                                )}
                                name="shippingState"
                                control={control}
                                rules={{
                                  required: "Please select state ",
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} lg={3}>
                              <Controller
                                render={({
                                  field: { ref, ...field },
                                  fieldState: { invalid, error },
                                }) => (
                                  <Input
                                    {...field}
                                    label="Pincode"
                                    value={field.value}
                                    required={true}
                                    error={Boolean(error)}
                                    helperText={
                                      error?.message ? error?.message : " "
                                    }
                                    onChange={field.onChange}
                                    inputRef={ref}
                                  />
                                )}
                                name="shippingPincode"
                                control={control}
                                rules={{
                                  required: "Please enter Pincode",
                                  pattern: {
                                    value: RegExpressions?.PinCode,
                                    message: "Please enter valid pin code",
                                  },
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} lg={3}>
                              <Controller
                                render={({
                                  field: { ref, ...field },
                                  fieldState: { invalid, error },
                                }) => (
                                  <Input
                                    {...field}
                                    label="Country"
                                    disabled
                                    value={"India"}
                                    required={true}
                                    error={Boolean(error)}
                                    helperText={
                                      error?.message ? error?.message : " "
                                    }
                                    onChange={field.onChange}
                                    inputRef={ref}
                                  />
                                )}
                                name="shippingCountry"
                                control={control}
                                rules={{
                                  required: "Please enter Country",
                                }}
                              />
                            </Grid>
                          </>
                        )}

                        <Grid
                          container
                          justifyContent={"flex-end"}
                          className="nextBtn"
                        >
                          <Grid item xs={12} sm={11.3} md={6} lg={2.6} xl={2.7}>
                            <Buttons
                              buttonType="submit"
                              variant="contained"
                              text="Next"
                              type="primary"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </form>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
