import { useEffect, useState } from "react";
import "./manageUserView.css";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  styled,
  useMediaQuery,
} from "@mui/material";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { backIcon, tickIcon } from "../../../../assets/icons";
import Buttons from "../../../common/button";
import "../style.css";
import {
  useDeactivateUserMutation,
  useDeleteUserMutation,
  useGetUserByIdQuery,
} from "../../../../redux/services/userApiSlice";
import { ConfirmModal } from "../../../common/confirmmodal";
import { Messages } from "../../../../constants/messages";
import { setAlert } from "../../../../redux/features/alert/alertSlice";
import {
  useForgotPasswordMutation,
  useResetPasscodeMutation,
} from "../../../../redux/services/authApiSlice";
import {
  addressFormat,
  handleErrors,
  phoneNumberAutoFormat,
} from "../../../../utils";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { UserRoles } from "../../../../constants/enum";
import { useGetUserSubscriptionPlanMutation } from "../../../../redux/services/planApiSlice";
import { CustomToolTip } from "../../../common/tooltip";
import { Page } from "../../../../routes/config";
import Loader from "../../../common/loader/loader";

export const ManageUserCard = styled((props: any) => {
  const { children, ...rest } = props;
  return <Card {...rest}>{children}</Card>;
})`
  ${(props) => props.cardHeight && `height:${props.cardHeight}px`}
`;

export const ManageUserView = () => {
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange" || "onSubmit",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const match = useMediaQuery("(max-width:899px)");
  const subsId = state?.subsId;
  const [userData, setUserData] = useState<any>();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [deactivateModal, setDeactivateModal] = useState(false);
  const [activateModal, setActivateModal] = useState(false);
  const [deactivateUser, deactivateUserInfo] = useDeactivateUserMutation();
  const [id, setId] = useState<any>();
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [cardHeight, setCardHeight] = useState(0);
  const {userInfo} = useSelector((state: any) => state?.auth);
  useEffect(() => {
    if (state?.view) {
      setId(state?.subsId);
    } else if (state?.benId) {
      setId(state?.benId);
    } else {
      setId(state?.userData?.User?.userId);
    }
  }, [subsId]);
  // API INTEGRATION

  const [forgetPassword, forgetPasswordInfo] = useForgotPasswordMutation();
  const [resetPasscode, resetPasscodeInfo] = useResetPasscodeMutation();
  const [deleteUser, deleteUserInfo] = useDeleteUserMutation();
  const [getSubscriptionPlan, getSubscriptionPlanData] =
    useGetUserSubscriptionPlanMutation();

  const {
    data,
    isSuccess,
    refetch,
    isError,
    fulfilledTimeStamp,
    error,
    isLoading,
  } = useGetUserByIdQuery(id, {
    skip: id === undefined,
  });

  const [planData, setPlanData] = useState([]);
  useEffect(() => {
    if (isSuccess) {
      const reqData = {
        roleId: data?.result?.roleId,
        roleName: data?.result?.roleName,
        User: {
          tenantId: data?.result?.tenantId,
          userId: data?.result?.userId,
          fullName: data?.result?.fullName,
          countryCode: data?.result?.countryCode,
          mobileNumber: data?.result?.mobileNumber,
          email: data?.result?.email,
          subscriberId: data?.result?.subscriberId,
          gender: data?.result?.gender,
          country: data?.result?.country,
          status: data?.result?.status,
          createdAt: data?.result?.createdAt,
          updatedAt: data?.result?.updatedAt,
          deletedAt: data?.result?.deletedAt,
          roleName: data?.result?.roleName,
          address: data?.result?.address,
        },
      };
      setUserData(reqData);
    }
  }, [data, isSuccess]);
  useEffect(() => {
    if (isError) {
      if (state?.view) {
        navigate(-1);
      }
      handleErrors({ error, isError }, dispatch);
    }
  }, [isError]);

  const handleReset = () => {
    setConfirmModalOpen(true);
  };
  const onCancelcallBack = () => {
    setConfirmModalOpen(false);
  };
  const onConfirmCallBack = () => {
    setConfirmModalOpen(false);

    if (
      userData?.roleName !== UserRoles?.BENEFICIARY &&
      userData?.roleName !== UserRoles?.FAMILY
    ) {
      forgetPassword({ email: userData?.User?.email });
    } else {
      let payload = {
        userId: userData?.User?.userId,
        mobileNumber: userData?.User?.mobileNumber,
        countryCode: userData?.User?.countryCode,
        roleName: userData?.User?.roleName,
        fullName: userData?.User?.fullName,
      };
      resetPasscode(payload);
    }
  };

  useEffect(() => {
    if (forgetPasswordInfo?.isSuccess) {
      dispatch(
        setAlert({
          alertType: "success",
          open: true,
          message: forgetPasswordInfo?.data?.message,
        })
      );
    }
  }, [forgetPasswordInfo?.isSuccess]);

  useEffect(() => {
    handleErrors(forgetPasswordInfo, dispatch);
  }, [forgetPasswordInfo?.isError]);

  useEffect(() => {
    if (resetPasscodeInfo?.isSuccess) {
      dispatch(
        setAlert({
          open: "true",
          alertType: "success",
          message: resetPasscodeInfo?.data?.result,
        })
      );
    }
  }, [resetPasscodeInfo?.isSuccess]);

  useEffect(() => {
    if (resetPasscodeInfo?.isError) {
      handleErrors(resetPasscodeInfo, dispatch);
    }
  }, [resetPasscodeInfo?.isError]);

  useEffect(() => {
    if (userData) {
      getSubscriptionPlan(userData?.User?.userId);
    }
  }, [userData]);

  useEffect(() => {
    if (getSubscriptionPlanData?.isSuccess) {
      setPlanData(getSubscriptionPlanData?.data?.result);
    }
  }, [getSubscriptionPlanData?.isSuccess]);

  useEffect(() => {
    if (getSubscriptionPlanData?.isError) {
      handleErrors(getSubscriptionPlanData, dispatch);
    }
  }, [getSubscriptionPlanData?.isError]);

  const handleViewBeneficiary = () => {
    navigate(Page.VIEWBENEFICIARY, {
      state: { userData: userData, type: UserRoles?.BENEFICIARY },
    });
  };
  const handleViewFamily = () => {
    navigate(Page.VIEWBENEFICIARY, {
      state: { userData: userData, type: UserRoles?.FAMILY },
    });
  };
  const handleManageSubscription = () => {
    navigate(Page.MANAGEUSER_SUBSCRIPTIONPLAN, {
      state: { view: true, userData: userData },
    });
  };
  const handleBillingHistory = () => {
    navigate(Page.MANAGEUSER_BILLINGHISTORY, {
      state: {
        view: true,
        userData: userData,
      },
    });
  };
  const handleOrderHistory = () => {
    navigate(Page.MANAGEUSER_ORDERHISTORY, {
      state: {
        view: true,
        userData: userData,
      },
    });
  };
  const handleDevice = () => {
    navigate(Page.MANAGEUSER_MANAGEDEVICES, {
      state: {
        view: true,
        userData: userData,
      },
    });
  };
  const handleViewSubscriber = () => {
    navigate(Page.MANAGEUSERVIEWSUBSCRIBER, {
      state: {
        view: true,
        userData: {},
        subsId: userData?.User?.subscriberId,
      },
    });
  };

  const handleViewAuditlogs = () => {
    navigate(Page.MANAGEUSERS_AUDITLOGS, {
      state: {
        view: true,
        userData: userData,
      },
    });
  };

  const handleViewNotification = () => {
    navigate(Page.MANAGEUSER_NOTIFICATION, {
      state: {
        view: true,
        userData: userData,
      },
    });
  };

  const onConfirmDeactivate = () => {
    deactivateUser({
      id: userData?.User?.userId,
      // subscriberId: userData?.User?.userId,
      status: "inactive",
    });
  };

  const onConfirmActivate = () => {
    deactivateUser({
      id: userData?.User?.userId,
      // subscriberId: userData?.User?.userId,
      status: "active",
    });
  };
  const onCancelActivate = () => {
    setActivateModal(false);
  };

  useEffect(() => {
    if (deactivateUserInfo?.isSuccess) {
      setDeactivateModal(false);
      setActivateModal(false);
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: deactivateUserInfo?.data?.message,
        })
      );
      refetch();
    }
  }, [deactivateUserInfo?.isSuccess]);

  useEffect(() => {
    if (deactivateUserInfo?.isError) {
      handleErrors(deactivateUserInfo, dispatch);
    }
  }, [deactivateUserInfo?.isError]);

  const handleDeactivate = () => {
    setDeactivateModal(true);
  };
  const handleActivate = () => {
    setActivateModal(true);
  };
  const onCancelDeactivate = () => {
    setDeactivateModal(false);
  };

  const calHeight = () => {
    let targetCompo,
      targetElement: any,
      elementHeight: number,
      cardHeight: number;
    targetCompo = window.document.querySelector<HTMLElement>(".manageUserView");
    targetElement = targetCompo?.getBoundingClientRect().y ?? 0;
    elementHeight = window.innerHeight;
    cardHeight = elementHeight - (targetElement + 25);
    setCardHeight(cardHeight);
  };

  useEffect(() => {
    calHeight();
    const resizeObserver = new ResizeObserver(() => {
      calHeight();
    });
    resizeObserver.observe(document.body);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);
  const handleDelete = () => {
    setDeleteUserModal(true);
  };
  const onCancelDelete = () => {
    setDeleteUserModal(false);
  };
  const onConfirmDelete = () => {
    deleteUser(userData?.User?.userId);
  };

  useEffect(() => {
    if (deleteUserInfo?.isSuccess) {
      setDeleteUserModal(false);
      dispatch(
        setAlert({
          open: "true",
          alertType: "success",
          message: deleteUserInfo?.data?.result,
        })
      );
      navigate(Page.MANAGEUSERS)
    }
  }, [deleteUserInfo?.isSuccess]);
  useEffect(() => {
    if (deleteUserInfo?.isError) {
      handleErrors(deleteUserInfo, dispatch);
    }
  }, [deleteUserInfo?.isError]);
  return (
    <>
      <Loader
        show={
          isLoading ||
          getSubscriptionPlanData?.isLoading ||
          deactivateUserInfo?.isLoading ||
          forgetPasswordInfo?.isLoading ||
          resetPasscodeInfo?.isLoading ||
          deleteUserInfo?.isLoading
        }
      />
      <Box marginBottom="150px">
        <Grid container className="mainHeading" spacing={2}>
          <Grid item xs={12} sm={12} md={7} lg={9} xl={8.8}>
            <Grid container className="topHeaderHeading" spacing={2}>
              <Grid item>
                <span className="backIcon-pd">
                  <img
                    src={backIcon}
                    alt="backIcon"
                    onClick={() => navigate(-1)}
                  />
                </span>
                <span className="adminfullName">
                  {userData?.User?.fullName}{" "}
                  <span className="fullNameRole">({userData?.roleName})</span>
                </span>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                lg={6}
                xl={7}
                className="activeGrid"
              >
                <img className="activeimg" src={tickIcon} />
                <span className="accountSubText activeSinceDate">
                  Active Since{" "}
                  {dayjs(userData?.User?.createdAt)?.format("MMM YYYY")}
                </span>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            lg={3}
            xl={3.03}
            className="buttonContainer"
          >
            <Grid container spacing={2} justifyContent="flex-end">
              {userData?.User?.status !== "invited" && userInfo?.roleName !== UserRoles?.PLATFORM_SUPPORT ? (
                <Grid item xs={5.5} sm={5.7} md={6} lg={6} xl={6}>
                  {userData?.User?.status === "active" ? (
                    <Buttons
                      type="primary"
                      variant="contained"
                      text="DEACTIVATE"
                      onClick={handleDeactivate}
                    ></Buttons>
                  ) : (
                    <Buttons
                      type="primary"
                      variant="contained"
                      text="ACTIVATE"
                      onClick={handleActivate}
                    ></Buttons>
                  )}
                </Grid>
              ) : null}
              {userInfo?.roleName !== UserRoles?.PLATFORM_SUPPORT ?<Grid item xs={5.5} sm={5.7} md={6} lg={6} xl={6}>
                <Buttons
                  type="secondary"
                  variant="contained"
                  text="DELETE"
                  onClick={handleDelete}
                ></Buttons>
              </Grid>:null}
            </Grid>
          </Grid>
        </Grid>

        <div className="manageUserView">
          <ManageUserCard className="innerMainCard" cardHeight={cardHeight}>
            <CardContent>
              <Grid container className="mainGridContainer">
                <Grid container className="gridConatiner1">
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    xl={4}
                    marginBottom={match ? "1.5vh" : ""}
                  >
                    <span className="settingSubHeading">Profile Details</span>
                  </Grid>
                  {/* full name  */}
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <span
                      className="subscriberDetail"
                      data-testid="account-username"
                    >
                      {userData?.User?.fullName}
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <span
                      className="subscriberDetail"
                      data-testid="account-username"
                    ></span>
                  </Grid>
                </Grid>

                {userData?.roleName === UserRoles.BENEFICIARY ||
                userData?.roleName === UserRoles.FAMILY ? (
                  <></>
                ) : (
                  <Grid container className="gridConatiner1">
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <span className="settingSubHeading"></span>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <span
                        className="subscriberColumn2"
                        data-testid="account-email"
                      >
                        <CustomToolTip
                          title={userData?.User?.email}
                          placement="top"
                        >
                          <p className="tooltipParagrah slice">
                            <span className="fw-700 slice">Email:&nbsp;</span>
                            {userData?.User?.email} &nbsp;
                          </p>
                        </CustomToolTip>
                      </span>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <span className="settingSubHeading"></span>
                    </Grid>
                  </Grid>
                )}

                <Grid container className="gridConatiner1">
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <span className="settingSubHeading"></span>
                  </Grid>
                  {/* mobile number  */}
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <span
                      className="subscriberColumn2"
                      data-testid="account-mobilenumber"
                    >
                      <span className="fw-700">Mobile Number:&nbsp;</span>
                      {userData?.User?.countryCode}&nbsp;
                      {phoneNumberAutoFormat(userData?.User?.mobileNumber)}
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <span className="settingSubHeading"></span>
                  </Grid>
                </Grid>

                <Grid container className="gridConatiner1">
                  <Grid item xl={4} md={4} sm={12} xs={12}>
                    <span className="settingSubHeading"></span>
                  </Grid>
                  <Grid item xl={4} md={4} sm={6} xs={6}>
                    <span
                      className="subscriberColumn2"
                      data-testid="account-password"
                    >
                      <span className="fw-700">Password:&nbsp;</span>**********
                    </span>
                  </Grid>
                  <Grid item xl={4} md={4} sm={6} xs={6}>
                    <span className="subscriptionColumn3" onClick={handleReset}>
                      {userData?.roleName !== UserRoles?.BENEFICIARY &&
                      userData?.roleName !== UserRoles?.FAMILY
                        ? "Reset Password"
                        : "Reset Passcode"}
                    </span>
                  </Grid>
                </Grid>

                <Grid container className="gridConatiner1">
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <span className="settingSubHeading"></span>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <span
                      className="subscriberColumn2"
                      data-testid="account-address"
                    >
                      <span className="fw-700">Address:&nbsp;</span>
                      {addressFormat(userData?.User?.address)}
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}></Grid>
                </Grid>
                {userData?.roleName === UserRoles.PLATFORM_ADMIN ||
                userData?.roleName === UserRoles.PLATFORM_SUPER_ADMIN ||
                userData?.roleName === UserRoles.PLATFORM_SUPPORT ? (
                  <>
                    <Grid container className="gridConatiner1">
                      <Grid item xl={4} md={4} sm={12} xs={12}>
                        <span className="settingSubHeading"></span>
                      </Grid>
                      <Grid item xl={4} md={4} sm={6} xs={6}>
                        <span className="subscriberColumn2">
                          <span className="fw-700">Tenant Name:&nbsp;</span>
                          Family Pro Health
                        </span>
                      </Grid>
                      <Grid item xl={4} md={4} sm={6} xs={6}>
                        <span className="subscriptionColumn3"></span>
                      </Grid>
                    </Grid>
                    <Grid container className="gridConatiner1 lastLink">
                      <Grid item xl={4} md={4} sm={12} xs={12}>
                        <span className="settingSubHeading"></span>
                      </Grid>
                      <Grid item xl={4} md={4} sm={6} xs={6}>
                        <span className="subscriberColumn2">
                          <span className="fw-700">Tenant Location:&nbsp;</span>
                          India
                        </span>
                      </Grid>
                      <Grid item xl={4} md={4} sm={6} xs={6}></Grid>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
            </CardContent>
            <Divider variant="fullWidth" />

            {userData?.roleName === UserRoles.SUBSCRIBER ? (
              <>
                {/* subscription  */}
                <CardContent>
                  <Grid container className="mainGridContainer2">
                    <Grid container className="lastLink">
                      <Grid
                        marginBottom={match ? "1.5vh" : ""}
                        item
                        xl={4}
                        md={4}
                        sm={12}
                        xs={12}
                      >
                        <span
                          className="settingSubHeading"
                          data-testid="account-subcribe-text"
                        >
                          Subscriptions
                        </span>
                      </Grid>
                      <Grid item xl={4} md={4} sm={6} xs={6}>
                        <span
                          className="subscriberDetail"
                          data-testid="account-subcribe-value"
                        >
                          {planData?.map((plan: any) => plan?.name).join(", ")}
                        </span>
                      </Grid>
                      <Grid item xl={4} md={4} sm={6} xs={6}>
                        <span
                          className="subscriptionColumn3"
                          onClick={handleManageSubscription}
                        >
                          Manage Subscription
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider variant="fullWidth" />

                {/* billing  */}
                <CardContent>
                  <Grid container className="mainGridContainer2">
                    <Grid container className="lastLink">
                      <Grid item xl={4} md={4} sm={4} xs={4}>
                        <span
                          className="settingSubHeading"
                          data-testid="devices"
                        >
                          Billing
                        </span>
                      </Grid>
                      <Grid item xl={4} md={4} sm={4} xs={4}></Grid>
                      <Grid item xl={4} md={4} sm={4} xs={4}>
                        <span
                          className="subscriptionColumn3"
                          onClick={handleBillingHistory}
                        >
                          View Billing History
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider variant="fullWidth" />

                {/* order  */}
                <CardContent>
                  <Grid container className="mainGridContainer2">
                    <Grid container className="lastLink">
                      <Grid item xl={4} md={4} sm={4} xs={4}>
                        <span
                          className="settingSubHeading"
                          data-testid="devices"
                        >
                          Order
                        </span>
                      </Grid>
                      <Grid item xl={4} md={4} sm={4} xs={4}></Grid>
                      <Grid item xl={4} md={4} sm={4} xs={4}>
                        <span
                          className="subscriptionColumn3"
                          onClick={handleOrderHistory}
                        >
                          View Order History
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider variant="fullWidth" />

                {/* device  */}
                <CardContent>
                  <Grid container className="mainGridContainer2">
                    <Grid container className="lastLink">
                      <Grid item xl={4} md={4} sm={4} xs={4}>
                        <span
                          className="settingSubHeading"
                          data-testid="devices"
                        >
                          Device
                        </span>
                      </Grid>
                      <Grid item xl={4} md={4} sm={4} xs={4}></Grid>
                      <Grid item xl={4} md={4} sm={4} xs={4}>
                        <span
                          className="subscriptionColumn3"
                          onClick={handleDevice}
                        >
                          View Device
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider variant="fullWidth" />

                {/* notification  */}
                <CardContent>
                  <Grid container className="mainGridContainer2">
                    <Grid container className="lastLink">
                      <Grid item xl={4} md={4} sm={4} xs={4}>
                        <span
                          className="settingSubHeading"
                          data-testid="devices"
                        >
                          Notification
                        </span>
                      </Grid>
                      <Grid item xl={4} md={4} sm={4} xs={4}></Grid>
                      <Grid item xl={4} md={4} sm={4} xs={4}>
                        <span
                          className="subscriptionColumn3"
                          onClick={handleViewNotification}
                        >
                          View notification
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider variant="fullWidth" />

                {/* audit logs  */}
                <CardContent>
                  <Grid container className="mainGridContainer2">
                    <Grid container className="lastLink">
                      <Grid item xl={4} md={4} sm={4} xs={4}>
                        <span
                          className="settingSubHeading"
                          data-testid="devices"
                        >
                          Audit Logs
                        </span>
                      </Grid>
                      <Grid item xl={4} md={4} sm={4} xs={4}></Grid>
                      <Grid item xl={4} md={4} sm={4} xs={4}>
                        <span
                          className="subscriptionColumn3"
                          onClick={handleViewAuditlogs}
                        >
                          View Audit Logs
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider variant="fullWidth" />

                {/* benificiary/family  */}
                <CardContent>
                  <Grid container className="mainGridContainer2">
                    <Grid container className="lastLink">
                      <Grid item xl={4} md={4} sm={4} xs={4}>
                        <span
                          className="settingSubHeading"
                          data-testid="devices"
                        >
                          Beneficiary / Family
                        </span>
                      </Grid>
                      <Grid item xl={4} md={4} sm={4} xs={4}></Grid>
                      <Grid item xl={4} md={4} sm={4} xs={4}>
                        <span className="subscriptionColumn3">
                          <span onClick={handleViewBeneficiary}>
                            {" "}
                            View Beneficiary &nbsp;
                          </span>
                          <span> /</span>{" "}
                          <span onClick={handleViewFamily}>
                            &nbsp;View Family
                          </span>
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider variant="fullWidth" />
              </>
            ) : (
              <></>
            )}

            {/* Beneficiary and Family */}

            {userData?.roleName === UserRoles.BENEFICIARY ||
            userData?.roleName === UserRoles.FAMILY ? (
              <>
                {/* subscriber  */}
                <CardContent>
                  <Grid container className="mainGridContainer2">
                    <Grid container className="lastLink">
                      <Grid item xl={4} md={4} sm={4} xs={4}>
                        <span
                          className="settingSubHeading"
                          data-testid="devices"
                        >
                          Subscriber
                        </span>
                      </Grid>
                      <Grid item xl={4} md={4} sm={4} xs={4}></Grid>
                      <Grid item xl={4} md={4} sm={4} xs={4}>
                        <span
                          className="subscriptionColumn3"
                          onClick={handleViewSubscriber}
                        >
                          View Subscriber
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider variant="fullWidth" />

                {/* notification  */}
                <CardContent>
                  <Grid container className="mainGridContainer2">
                    <Grid container className="lastLink">
                      <Grid item xl={4} md={4} sm={4} xs={4}>
                        <span
                          className="settingSubHeading"
                          data-testid="devices"
                        >
                          Notification
                        </span>
                      </Grid>
                      <Grid item xl={4} md={4} sm={4} xs={4}></Grid>
                      <Grid item xl={4} md={4} sm={4} xs={4}>
                        <span
                          className="subscriptionColumn3"
                          onClick={handleViewNotification}
                        >
                          View notification
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider variant="fullWidth" />

                {/* audit logs  */}
                <CardContent>
                  <Grid container className="mainGridContainer2">
                    <Grid container className="lastLink">
                      <Grid item xl={4} md={4} sm={4} xs={4}>
                        <span
                          className="settingSubHeading"
                          data-testid="devices"
                        >
                          Audit Logs
                        </span>
                      </Grid>
                      <Grid item xl={4} md={4} sm={4} xs={4}></Grid>
                      <Grid item xl={4} md={4} sm={4} xs={4}>
                        <span
                          className="subscriptionColumn3"
                          onClick={handleViewAuditlogs}
                        >
                          View Audit Logs
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider variant="fullWidth" />
              </>
            ) : (
              <></>
            )}

            {userData?.roleName === UserRoles.PLATFORM_ADMIN ||
            userData?.roleName === UserRoles.PLATFORM_SUPPORT ||
            userData?.roleName === UserRoles.PLATFORM_SUPER_ADMIN ? (
              <>
                {/* notification  */}
                <CardContent>
                  <Grid container className="mainGridContainer2">
                    <Grid container className="lastLink">
                      <Grid item xl={4} md={4} sm={4} xs={4}>
                        <span
                          className="settingSubHeading"
                          data-testid="devices"
                        >
                          Notification
                        </span>
                      </Grid>
                      <Grid item xl={4} md={4} sm={4} xs={4}></Grid>
                      <Grid item xl={4} md={4} sm={4} xs={4}>
                        <span
                          className="subscriptionColumn3"
                          onClick={handleViewNotification}
                        >
                          View notification
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider variant="fullWidth" />

                {/* audit logs  */}
                <CardContent>
                  <Grid container className="mainGridContainer2">
                    <Grid container className="lastLink">
                      <Grid item xl={4} md={4} sm={4} xs={4}>
                        <span
                          className="settingSubHeading"
                          data-testid="devices"
                        >
                          Audit Logs
                        </span>
                      </Grid>
                      <Grid item xl={4} md={4} sm={4} xs={4}></Grid>
                      <Grid item xl={4} md={4} sm={4} xs={4}>
                        <span
                          className="subscriptionColumn3"
                          onClick={handleViewAuditlogs}
                        >
                          View Audit Logs
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider variant="fullWidth" />
              </>
            ) : (
              <></>
            )}
          </ManageUserCard>
        </div>

        <div className="modelModal" data-testid="confirm-modal">
          <ConfirmModal
            className="confirmModal"
            key="disagree"
            open={confirmModalOpen}
            primaryText={
              userData?.roleName !== UserRoles?.BENEFICIARY &&
              userData?.roleName !== UserRoles?.FAMILY
                ? Messages.RESET_PASSWORD
                : Messages.RESET_PASSCODE
            }
            cancelButton="NO"
            confirmButton="YES"
            cancelCallback={onCancelcallBack}
            confirmCallback={onConfirmCallBack}
          />
        </div>
        <div className="modelModal" data-testid="confirm-modal">
          <ConfirmModal
            className="confirmModal"
            key="deactivate"
            open={deactivateModal}
            primaryText={Messages.DEACTIVATE}
            cancelButton="NO"
            confirmButton="YES"
            cancelCallback={onCancelDeactivate}
            confirmCallback={onConfirmDeactivate}
          />
        </div>
        <div className="modelModal" data-testid="confirm-modal">
          <ConfirmModal
            className="confirmModal"
            key="activate"
            open={activateModal}
            primaryText={Messages.ACTIVATE}
            cancelButton="NO"
            confirmButton="YES"
            cancelCallback={onCancelActivate}
            confirmCallback={onConfirmActivate}
          />
        </div>
        <div className="modelModal" data-testid="confirm-modal">
          <ConfirmModal
            className="confirmModal"
            key="DeleteUser"
            open={deleteUserModal}
            primaryText={Messages.DELETEUSER}
            cancelButton="NO"
            confirmButton="YES"
            cancelCallback={onCancelDelete}
            confirmCallback={onConfirmDelete}
          />
        </div>
      </Box>
    </>
  );
};
