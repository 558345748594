import { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Dialog,
  Divider,
  Grid,
  styled,
  useMediaQuery,
} from "@mui/material";
import "./style.css";
import { useForm } from "react-hook-form";
import { useGetAccountSettingsQuery } from "../../../redux/services/accountsettingsApiSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  addressFormat,
  handleErrors,
  phoneNumberAutoFormat,
} from "../../../utils";
import { useNavigate } from "react-router";
import { Page } from "../../../routes/config";
import tickIcon from "../../../assets/icons/tick.svg";
import backIcon from "../../../assets/icons/backIcon.svg";
import { UserRoles } from "../../../constants/enum";
import {
  useDeleteOrderMutation,
  useGetUserSubscriptionPlanMutation,
} from "../../../redux/services/planApiSlice";
import dayjs from "dayjs";
import { CustomToolTip } from "../../common/tooltip";
import { NameModal } from "./modal/nameModal";
import { EmailModal } from "./modal/emailModal";
import { MobileNumberModal } from "./modal/mobileNumberModal";
import { PasswordModal } from "./modal/passwordModal";
import { BillingAddressModal } from "./modal/billingAddressModal";
import Loader from "../../common/loader/loader";
import { PrefferedCommunicationModal } from "./modal/prefferedCommunication";
import {
  useCustomerPaymentPortalMutation,
  useGetPaymentMethodQuery,
} from "../../../redux/services/userApiSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCcMastercard, faCcVisa } from "@fortawesome/free-brands-svg-icons";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { masterCard, visaCard } from "../../../assets/images";
import Buttons from "../../common/button";

export const AccountSettingCard = styled((props: any) => {
  const { children, ...rest } = props;
  return <Card {...rest}>{children}</Card>;
})`
  ${(props) => props.cardHeight && `height:${props.cardHeight}px !important`}
`;

export const AccountSettings = () => {
  const { setValue } = useForm({ mode: "onChange" || "onSubmit" });
  const { userInfo } = useSelector((state: any) => state.auth);
  const orderId = useSelector((state: any) => state?.order?.id);

  const [changeName, setChangeName] = useState(false);
  const [changeEmail, setChangeEmail] = useState(false);
  const [communication, setCommunication] = useState(false);
  const [changeMobile, setChangeMobile] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [openMobileOtp, setOpenMobileOtp] = useState(false);
  const [address, setAddress] = useState(false);
  const [tokenId, setTokenId] = useState();
  const [cardHeight, setCardHeight] = useState(0);
  const [cardPayment, setCardpayment] = useState<any>();
  console.log(cardPayment, "cardPaymet");
  const [cardNumber, setcardNumber] = useState<any>();
  console.log(cardNumber, "cardnumber");
  const [planData, setPlanData] = useState<any>([]);
  const [getSubscriptionPlan, getSubscriptionPlanData] =
    useGetUserSubscriptionPlanMutation();
  const [stripeId, setStripeId] = useState<any>()

  

  const [billingData, setBillingData] = useState({
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
  });
  const dispatch = useDispatch();
  const { id } = useSelector((state: any) => state?.order);
  const userId = useSelector((state: any) => state?.auth?.userId);
  // const userInfo = useSelector((state: any) => state?.auth?.userInfo);

  //  API INTEGRATION
  const { data, refetch, isError, error, isLoading, isSuccess } =
    useGetAccountSettingsQuery(userId, {
      refetchOnMountOrArgChange: true,
    });

  const {
    data: paymentMethod,
    refetch: paymentRefetch,
    isError: paymentIsError,
    error: PaymentError,
    isLoading: paymentIsLoading,
    isSuccess: paymentIsSuccess,
  } = useGetPaymentMethodQuery(userId, {
    refetchOnMountOrArgChange: true,
    // skip: !(userInfo?.stripeCustomerId)
    // skip:stripeValue ? false : true,
    skip:stripeId ? false : true
  });
  console.log(paymentMethod?.result?.card?.brand, "paymentMethod");

  const [customerPortal, customerPortalInfo] =
    useCustomerPaymentPortalMutation();

  const openNameModal = () => {
    setChangeName(true);
  };

  const openEmailModal = () => {
    setChangeEmail(true);
  };

  const openMobileModal = () => {
    setChangeMobile(true);
  };

  const openPasswordModal = () => {
    setChangePassword(true);
  };

  const handleCommunicationModal = () => {
    setCommunication(true);
  };

  useEffect(() => {
    if (userInfo?.roleName === UserRoles.SUBSCRIBER) {
      getSubscriptionPlan(userInfo?.userId);
    }
  }, []);

  useEffect(() => {
    if (getSubscriptionPlanData?.isSuccess) {
      if(getSubscriptionPlanData?.data && getSubscriptionPlanData?.data?.length > 0){
        setPlanData(getSubscriptionPlanData?.data?.result);
      }
    }
  }, [getSubscriptionPlanData?.isSuccess]);


  useEffect(()=>{
    if(getSubscriptionPlanData?.data && getSubscriptionPlanData?.data?.result && getSubscriptionPlanData?.data?.result?.length > 0 ){
      setStripeId(getSubscriptionPlanData?.data?.result[0]?.stripeSubscriptionId)
      console.log(getSubscriptionPlanData?.data?.result[0]?.stripeSubscriptionId, "lengthstripe")
    }
  },[getSubscriptionPlanData?.isSuccess])

  useEffect(() => {
    if (getSubscriptionPlanData?.isError) {
      handleErrors(getSubscriptionPlanData, dispatch);
    }
  }, [getSubscriptionPlanData?.isError]);

  useEffect(() => {
    if (isSuccess) {
      setBillingData(data?.result?.address);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    handleErrors({ error, isError }, dispatch);
  }, [isError]);

  const handleAddressClick = () => {
    setAddress(true);
  };

  const navigate = useNavigate();

  const match = useMediaQuery("(max-width:899px)");

  const handleColItemClick = (endpoint: any) => {
    navigate(`${Page.ACCOUNTSETTING}/${endpoint}`);
  };

  const calHeight = () => {
    let targetCompo,
      targetElement: any,
      elementHeight: number,
      cardHeight: number;
    targetCompo = window.document.querySelector<HTMLElement>(".accountSetting");
    targetElement = targetCompo?.getBoundingClientRect().y ?? 0;
    elementHeight = window.innerHeight;
    cardHeight = elementHeight - (targetElement + 25);
    setCardHeight(cardHeight);
  };

  useEffect(() => {
    calHeight();
    const resizeObserver = new ResizeObserver(() => {
      calHeight();
    });
    resizeObserver.observe(document.body);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const handleAuditlogs = () => {
    navigate(Page.ACCOUNTSETTINGS_AUDITLOGS, {
      state: {
        userData: data?.result,
      },
    });
  };

  console.log(planData, "dladddddddddddddd");

  useEffect(() => {
    setCardpayment(paymentMethod?.result?.card?.brand);
    setcardNumber(paymentMethod?.result?.card?.last4);
    if (paymentMethod?.result?.card?.brand === "visa") {
      setCardpayment(<img className="visa" src={visaCard} />);
    } else if (paymentMethod?.result?.card?.brand === "mastercard") {
      setCardpayment(
        <img className="mastercard" src={masterCard} />
      );
    } else {
      setCardpayment(<CreditCardIcon className="payment" />);
    }
  }, [paymentIsSuccess]);

  useEffect(() => {
    if (customerPortalInfo?.isSuccess) {
      const redirect = customerPortalInfo?.data?.result?.url;
      window.location.href = redirect;
      console.log(redirect, "redirect");
    }
  }, [customerPortalInfo?.isSuccess]);

  useEffect(() => {
    if (customerPortalInfo?.isError) {
      handleErrors(customerPortalInfo, dispatch);
    }
  }, [customerPortalInfo?.isError]);

  const changePayment = () => {
    customerPortal(userId);
  };
  const [existingUser, setExistingUser] = useState<any>(false);

  const exixstingSubscription = () => {
    setExistingUser(true);
  };
  const existingModalClose = () => {
    setExistingUser(false);
  };

  return (
    <>
      <Loader show={isLoading} />
      <Box marginBottom="150px">
        <Grid container className="mainHeading">
          <span className="accountHeading" data-testid="my-account">
            {userInfo?.roleName === UserRoles.SUBSCRIBER ? (
              <>
                My Account&nbsp;
                <img src={tickIcon} />
                &nbsp;&nbsp;
                <span className="accountSubText">
                  Active Since {dayjs(userInfo?.createdAt)?.format("MMM YYYY")}
                </span>
              </>
            ) : (
              <>
                <span className="accountSettingbackIcon">
                  <img
                    src={backIcon}
                    alt="backIcon"
                    onClick={() => navigate(-1)}
                  />
                </span>{" "}
                My Account
              </>
            )}
          </span>
        </Grid>

        <div className="accountSetting">
          <AccountSettingCard
            className="innerMainCard"
            data-testid="accountsetting-card"
            cardHeight={cardHeight}
            // sx={{ minHeight: 800 }}
          >
            <CardContent>
              <Grid container className="mainGridContainer">
                <Grid container className="gridConatiner1">
                  <Grid
                    marginBottom={match ? "1.5vh" : ""}
                    item
                    xl={4}
                    md={4}
                    sm={12}
                    xs={12}
                  >
                    <span
                      className="settingSubHeading"
                      data-testid="profile-details"
                    >
                      Profile Details
                    </span>
                  </Grid>
                  <Grid item xl={4} md={4} sm={6} xs={6}>
                    <span
                      className="subscriberDetail"
                      data-testid="account-username"
                    >
                      {data?.result?.fullName}
                    </span>
                  </Grid>
                  <Grid item xl={4} md={4} sm={6} xs={6}>
                    <span
                      className="subscriptionColumn3"
                      onClick={openNameModal}
                    >
                      Change Name
                    </span>
                  </Grid>
                </Grid>
                <Grid container className="gridConatiner1">
                  <Grid item xl={4} md={4} sm={12} xs={12}>
                    <span className="settingSubHeading"></span>
                  </Grid>
                  <Grid item xl={4} md={4} sm={6} xs={6}>
                    <span
                      className="subscriberColumn2"
                      data-testid="account-email"
                    >
                      <CustomToolTip
                        title={data?.result?.email}
                        placement="top"
                      >
                        <p className="tooltipParagrah slice">
                          <span className="fw-700">Email:&nbsp;</span>
                          {data?.result?.email}
                        </p>
                      </CustomToolTip>
                    </span>
                  </Grid>
                  <Grid item xl={4} md={4} sm={6} xs={6}>
                    <span
                      className="subscriptionColumn3"
                      onClick={openEmailModal}
                    >
                      Change Email
                    </span>
                  </Grid>
                </Grid>
                <Grid container className="gridConatiner1">
                  <Grid item xl={4} md={4} sm={12} xs={12}>
                    <span className="settingSubHeading"></span>
                  </Grid>
                  <Grid item xl={4} md={4} sm={6} xs={6}>
                    <span
                      className="subscriberColumn2"
                      data-testid="account-mobilenumber"
                    >
                      <span className="fw-700">Mobile Number:&nbsp;</span>
                      {data?.result?.countryCode}&nbsp;
                      {phoneNumberAutoFormat(data?.result?.mobileNumber)}
                    </span>
                  </Grid>
                  <Grid item xl={4} md={4} sm={6} xs={6}>
                    <span
                      className="subscriptionColumn3"
                      onClick={openMobileModal}
                    >
                      Change Mobile Number
                    </span>
                  </Grid>
                </Grid>
                <Grid container className="gridConatiner1">
                  <Grid item xl={4} md={4} sm={12} xs={12}>
                    <span className="settingSubHeading"></span>
                  </Grid>
                  <Grid item xl={4} md={4} sm={6} xs={6}>
                    <span
                      className="subscriberColumn2"
                      data-testid="account-password"
                    >
                      <span className="fw-700">Password:&nbsp;</span>**********
                    </span>
                  </Grid>
                  <Grid item xl={4} md={4} sm={6} xs={6}>
                    <span
                      className="subscriptionColumn3"
                      onClick={openPasswordModal}
                    >
                      Change Password
                    </span>
                  </Grid>
                </Grid>
                {/* {userInfo?.roleName == UserRoles.SUBSCRIBER ? (
              <Grid container className="gridConatiner1">
                <Grid item xl={4} md={4} sm={12} xs={12}>
                  <span className="settingSubHeading"></span>
                </Grid>
              </Grid>
            ) : (
              <></>
            )} */}
                <Grid container className="gridConatiner1">
                  <Grid item xl={4} md={4} sm={12} xs={12}>
                    <span className="settingSubHeading"></span>
                  </Grid>
                  <Grid item xl={4} md={4} sm={6} xs={6}>
                    <span
                      className="subscriberColumn2"
                      data-testid="account-address"
                    >
                      <span className="fw-700">Address:&nbsp;</span>
                      {/* {billingData?.address1}, {billingData?.address2},{" "}
                  {billingData?.city}, {billingData?.state},{" "}
                  {billingData?.zipCode}, {billingData?.country} */}
                      {addressFormat(billingData)}
                    </span>
                  </Grid>
                  <Grid item xl={4} md={4} sm={6} xs={6}>
                    {userInfo?.roleName === UserRoles.SUBSCRIBER ? (
                      <span
                        className="subscriptionColumn3"
                        onClick={handleAddressClick}
                      >
                        Change Billing Address
                      </span>
                    ) : (
                      <span
                        className="subscriptionColumn3"
                        onClick={handleAddressClick}
                      >
                        Change Address
                      </span>
                    )}
                  </Grid>
                </Grid>

                {userInfo?.roleName === UserRoles?.SUBSCRIBER ? (
                  <>
                    <Grid container className="gridConatiner1">
                      <Grid item xl={4} md={4} sm={12} xs={12}>
                        <span className="settingSubHeading"></span>
                      </Grid>
                      <Grid item xl={4} md={4} sm={6} xs={6}>
                        <span
                          className="subscriberColumn2"
                          data-testid="account-password"
                        >
                          <span className="fw-700 showDisplay">
                            Payment Method:&nbsp;
                            <span className="cardDetail">
                              {cardPayment}&nbsp; **** **** **** {cardNumber}
                            </span>
                          </span>
                        </span>
                      </Grid>
                      {
                        userInfo?.roleName === UserRoles?.SUBSCRIBER ? (
                          stripeId ? (
                          <Grid item xl={4} md={4} sm={6} xs={6}>
                            <span
                              className="subscriptionColumn3"
                              onClick={changePayment}
                            >
                              Change Payment Method
                            </span>
                          </Grid>
                        ) : (
                          <Grid item xl={4} md={4} sm={6} xs={6}>
                            {/* <span
                              className="subscriptionColumn3"
                              onClick={exixstingSubscription}
                            >
                              Change Payment Method
                            </span> */}
                          </Grid>
                        )
                      ) : (
                        <Grid item xl={4} md={4} sm={6} xs={6}></Grid>
                      )
                      }
                    </Grid>
                  </>
                ) : (
                  <></>
                )}

                {userInfo?.roleName === UserRoles.SUBSCRIBER ? (
                  <>
                    <Grid container className="gridConatiner1">
                      <Grid item xl={4} md={4} sm={12} xs={12}>
                        <span className="settingSubHeading"></span>
                      </Grid>
                      <Grid item xl={4} md={4} sm={6} xs={6}></Grid>
                      <Grid item xl={4} md={4} sm={6} xs={6}>
                        <span
                          className="subscriptionColumn3"
                          onClick={() => handleColItemClick("billinghistory")}
                        >
                          Billing History
                        </span>
                      </Grid>
                    </Grid>
                    <Grid container className="gridConatiner1 lastLink">
                      <Grid item xl={4} md={4} sm={12} xs={12}>
                        <span className="settingSubHeading"></span>
                      </Grid>
                      <Grid item xl={4} md={4} sm={6} xs={6}></Grid>
                      <Grid item xl={4} md={4} sm={6} xs={6}>
                        <span
                          className="subscriptionColumn3 "
                          onClick={() => handleColItemClick("orderhistory")}
                        >
                          Order History
                        </span>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
                {userInfo?.roleName !== UserRoles.SUBSCRIBER ? (
                  <>
                    <Grid container className="gridConatiner1">
                      <Grid item xl={4} md={4} sm={12} xs={12}>
                        <span className="settingSubHeading"></span>
                      </Grid>
                      <Grid item xl={4} md={4} sm={6} xs={6}>
                        <span className="subscriberColumn2">
                          <span className="fw-700">Tenant Name:&nbsp;</span>
                          Family Pro Health
                        </span>
                      </Grid>
                      <Grid item xl={4} md={4} sm={6} xs={6}></Grid>
                    </Grid>
                    <Grid container className="gridConatiner1 lastLink">
                      <Grid item xl={4} md={4} sm={12} xs={12}>
                        <span className="settingSubHeading"></span>
                      </Grid>
                      <Grid item xl={4} md={4} sm={6} xs={6}>
                        <span className="subscriberColumn2">
                          <span className="fw-700">Tenant Location:&nbsp;</span>
                          India
                        </span>
                      </Grid>
                      <Grid item xl={4} md={4} sm={6} xs={6}></Grid>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
            </CardContent>
            <Divider variant="fullWidth" />
            {userInfo?.roleName === UserRoles.SUBSCRIBER ? (
              <>
                <CardContent>
                  <Grid container className="mainGridContainer2">
                    <Grid container className="lastLink">
                      <Grid
                        marginBottom={match ? "1.5vh" : ""}
                        item
                        xl={4}
                        md={4}
                        sm={12}
                        xs={12}
                      >
                        <span
                          className="settingSubHeading"
                          data-testid="account-subcribe-text"
                        >
                          Subscriptions
                        </span>
                      </Grid>
                      <Grid item xl={4} md={4} sm={6} xs={6}>
                        <span
                          className="subscriberDetail"
                          data-testid="account-subcribe-value"
                        >
                          {planData.map((plan: any) => plan?.name).join(", ")}
                        </span>
                      </Grid>
                      <Grid item xl={4} md={4} sm={6} xs={6}>
                        <span
                          className="subscriptionColumn3"
                          onClick={() => handleColItemClick("subscriptionplan")}
                        >
                          Manage Subscription
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider variant="fullWidth" />
              </>
            ) : (
              <></>
            )}

            {userInfo?.roleName === UserRoles.SUBSCRIBER ? (
              <>
                <CardContent>
                  <Grid container className="mainGridContainer2">
                    <Grid container className="lastLink">
                      <Grid item xl={4} md={4} sm={4} xs={4}>
                        <span className="settingSubHeading">
                          Preferred Communication
                        </span>
                      </Grid>
                      <Grid item xl={4} md={4} sm={4} xs={4}></Grid>
                      <Grid item xl={4} md={4} sm={4} xs={4}>
                        <span
                          className="subscriptionColumn3"
                          onClick={handleCommunicationModal}
                        >
                          Change Preferred Communication
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider variant="fullWidth" />
              </>
            ) : (
              <></>
            )}

            {userInfo?.roleName === UserRoles.SUBSCRIBER ? (
              <>
                {/* device  */}
                <CardContent>
                  <Grid container className="mainGridContainer2">
                    <Grid container className="lastLink">
                      <Grid item xl={4} md={4} sm={4} xs={4}>
                        <span
                          className="settingSubHeading"
                          data-testid="devices"
                        >
                          Devices
                        </span>
                      </Grid>
                      <Grid item xl={4} md={4} sm={4} xs={4}></Grid>
                      <Grid item xl={4} md={4} sm={4} xs={4}>
                        <span
                          className="subscriptionColumn3"
                          onClick={() => handleColItemClick("managedevice")}
                        >
                          Manage Devices
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider variant="fullWidth" />
              </>
            ) : userInfo?.roleName === UserRoles?.PLATFORM_ADMIN ? (
              <>
                {/* audit log  */}
                <CardContent>
                  <Grid container className="mainGridContainer2">
                    <Grid container className="lastLink">
                      <Grid item xl={4} md={4} sm={4} xs={4}>
                        <span className="settingSubHeading">Audit Logs</span>
                      </Grid>
                      <Grid item xl={4} md={4} sm={4} xs={4}></Grid>
                      <Grid item xl={4} md={4} sm={4} xs={4}>
                        <span
                          className="subscriptionColumn3"
                          onClick={handleAuditlogs}
                        >
                          View Audit Logs
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider variant="fullWidth" />

                {/* communication  */}
                <CardContent>
                  <Grid container className="mainGridContainer2">
                    <Grid container className="lastLink">
                      <Grid item xl={4} md={4} sm={4} xs={4}>
                        <span
                          className="settingSubHeading"
                          data-testid="preferred-communication"
                        >
                          Preferred Communication
                        </span>
                      </Grid>
                      <Grid item xl={4} md={4} sm={4} xs={4}></Grid>
                      <Grid item xl={4} md={4} sm={4} xs={4}>
                        <span
                          className="subscriptionColumn3"
                          onClick={handleCommunicationModal}
                        >
                          Change Preferred Communication
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </>
            ) : (
              <></>
            )}

            {userInfo?.roleName === UserRoles?.PLATFORM_SUPER_ADMIN ? (
              <>
                <CardContent>
                  <Grid container className="mainGridContainer2">
                    <Grid container className="lastLink">
                      <Grid item xl={4} md={4} sm={4} xs={4}>
                        <span className="settingSubHeading">Audit Logs</span>
                      </Grid>
                      <Grid item xl={4} md={4} sm={4} xs={4}></Grid>
                      <Grid item xl={4} md={4} sm={4} xs={4}>
                        <span
                          className="subscriptionColumn3"
                          onClick={handleAuditlogs}
                        >
                          View Audit Logs
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider variant="fullWidth" />
              </>
            ) : (
              <></>
            )}

            {userInfo?.roleName === UserRoles?.PLATFORM_SUPPORT ? (
              <>
                <CardContent>
                  <Grid container className="mainGridContainer2">
                    <Grid container className="lastLink">
                      <Grid item xl={4} md={4} sm={4} xs={4}>
                        <span
                          className="settingSubHeading"
                          data-testid="preferred-communication"
                        >
                          Preferred Communication
                        </span>
                      </Grid>
                      <Grid item xl={4} md={4} sm={4} xs={4}></Grid>
                      <Grid item xl={4} md={4} sm={4} xs={4}>
                        <span
                          className="subscriptionColumn3"
                          onClick={handleCommunicationModal}
                        >
                          Change Preferred Communication
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </>
            ) : (
              <></>
            )}

            <NameModal
              changeName={changeName}
              setChangeName={setChangeName}
              refetch={refetch}
              name={data?.result?.fullName}
            />
            <PrefferedCommunicationModal
              communication={communication}
              setCommunication={setCommunication}
            />
            <EmailModal
              changeEmail={changeEmail}
              setChangeEmail={setChangeEmail}
              otpModal={otpModal}
              setOtpModal={setOtpModal}
              refetch={refetch}
              selectedEmail={data?.result?.email}
            />
            <MobileNumberModal
              changeMobile={changeMobile}
              setChangeMobile={setChangeMobile}
              openMobileOtp={openMobileOtp}
              setOpenMobileOtp={setOpenMobileOtp}
              refetch={refetch}
              selectedMobileNumber={data?.result?.mobileNumber}
              selectedCountryCode={data?.result?.countryCode}
              selectedCountry={data?.result?.country}
            />
            <PasswordModal
              changePassword={changePassword}
              setChangePassword={setChangePassword}
              refetch={refetch}
            />
            {address && (
              <BillingAddressModal
                address={address}
                setAddress={setAddress}
                billingData={billingData}
                refetch={refetch}
              />
            )}
          </AccountSettingCard>
        </div>
      </Box>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={existingUser}
        className="PaymentModal"
      >
        <div className="existingUserModal">
          <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            <div className="supportModalConfirmation">
              <span className="existingMessage">
                Change Payment Method functionality is not supported for your
                account. Please contact our support by email or phone mentioned
                below.{" "}
              </span>
              <div className="support">
                <span> Email Id: customercare@familyprohealth.com</span>
                <span>Technical support: +91 91089 45797</span>
                <span>Customer care: +1 833 776 4584</span>
              </div>
            </div>

            <Buttons
              onClick={existingModalClose}
              variant="contained"
              type="primary"
              text="OK"
              className="existingBtn"
            />
          </Grid>
        </div>
      </Dialog>
    </>
  );
};
